import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Web3 from "web3";
import BaseContractInfo from "../contracts/BaseContractInfo.json";
import TestBaseContractInfo from "../contracts/TestBaseContractInfo.json";
import BurnRedeemContractInfo from "../contracts/ERC721BurnRedeem.json";
import TestBurnRedeemContractInfo from "../contracts/TestERC721BurnRedeem.json";

const initialState = {
  status: "wait",
  settingData: undefined,
  logText: "",
  isBonusOpen: false,
  isBonusMain: false,
};

const serverSlice = createSlice({
  name: "serverSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(asyncPostBurnSettings.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncPostBurnSettings.fulfilled, (state, action) => {
        state.status = "finish";
      })
      .addCase(asyncPostBurnSettings.rejected, (state, action) => {
        console.log("[ERROR]asyncPostBurnSettings");
        state.status = "error";
      });

    builder
      .addCase(asyncGetBurnSettingData.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncGetBurnSettingData.fulfilled, (state, action) => {
        state.status = "finish";
        state.settingData = action.payload;
      })
      .addCase(asyncGetBurnSettingData.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(asyncPostOpenBonus.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncPostOpenBonus.fulfilled, (state, action) => {
        state.status = "finish";
      })
      .addCase(asyncPostOpenBonus.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(asyncPostChangeMain.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncPostChangeMain.fulfilled, (state, action) => {
        state.status = "finish";
      })
      .addCase(asyncPostChangeMain.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(asyncGetIsBonusOpen.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncGetIsBonusOpen.fulfilled, (state, action) => {
        state.isBonusOpen = action.payload.isBonusOpen;
        state.status = "finish";
      })
      .addCase(asyncGetIsBonusOpen.rejected, (state, action) => {
        state.status = "error";
      });

    builder
      .addCase(asyncGetIsBonusMain.pending, (state, action) => {
        state.status = "saving";
      })
      .addCase(asyncGetIsBonusMain.fulfilled, (state, action) => {
        state.isBonusMain = action.payload.isBonusMain;
        state.status = "finish";
      })
      .addCase(asyncGetIsBonusMain.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

const asyncPostSettings = createAsyncThunk(
  "serverSlice/asyncPostSettings",
  async (type, { getState }) => {
    let selectedData = [];
    if (type === "claim") {
      const { manifoldClaim } = getState();
      selectedData = manifoldClaim.selectedData;
    } else {
      const { manifoldBurn } = getState();
      selectedData = manifoldBurn.selectedData;
    }
    let result = { type: type, data: selectedData };

    const res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/admin/saveSettings",
      result
    );

    return res;
  }
);

const asyncPostBurnSettings = createAsyncThunk(
  "serverSlice/asyncPostBurnSettings",
  async (params, { getState }) => {
    //const { burnRawData } = params;
    const { manifoldBurn } = getState();

    console.log("manifoldBurn.rawData", manifoldBurn.rawData.data);
    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL +
          "/admin/saveBurnSettings/" +
          process.env.REACT_APP_API_KEY,
        manifoldBurn.rawData.data
      );
      console.log("---SAVE DATA SUCCESS---");
      return res;
    } catch (e) {
      console.log("[ERROR]asyncPostBurnSettings : " + JSON.stringify(e));
    }
  }
);

const asyncGetBurnSettingData = createAsyncThunk(
  "serverSlice/asyncGetBurnSettingData",
  async () => {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/public/burnSettingData"
    );
    console.log("serverSlice/asyncGetBurnSettingData : server load finish");
    const result = await getBurnRedeemInfo(res.data);

    return result;
  }
);

const asyncPostOpenBonus = createAsyncThunk(
  "serverSlice/asyncPostOpenBonus",
  async (params) => {
    const { isBonusOpen } = params;

    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL +
          "/admin/openBonus/" +
          process.env.REACT_APP_API_KEY,
        { isBonusOpen: isBonusOpen }
      );
      console.log("isBonusOpen : " + isBonusOpen);
      return res;
    } catch (e) {
      console.log("[ERROR]asyncPostOpenBonus : " + JSON.stringify(e));
    }
  }
);

const asyncPostChangeMain = createAsyncThunk(
  "serverSlice/asyncPostChangeMain",
  async (params) => {
    const { isBonusMain } = params;

    try {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL +
          "/admin/setIsBonusMain/" +
          process.env.REACT_APP_API_KEY,
        { isBonusMain: isBonusMain }
      );
      console.log("isBonusMain : " + isBonusMain);
      return res;
    } catch (e) {
      console.log("[ERROR]asyncPostChangeMain : " + JSON.stringify(e));
    }
  }
);

const asyncGetIsBonusOpen = createAsyncThunk(
  "serverSlice/asyncGetIsBonusOpen",
  async () => {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/public/isBonusOpen"
    );
    console.log("res : " + JSON.stringify(res.data));
    return res.data;
  }
);

const asyncGetIsBonusMain = createAsyncThunk(
  "serverSlice/asyncGetIsBonusMain",
  async () => {
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/public/isBonusMain"
    );
    console.log("res : " + JSON.stringify(res.data));
    return res.data;
  }
);

async function getBurnRedeemInfo(rawData) {
  let ABI, BaseContractAddress, startId;
  if (process.env.REACT_APP_VER === "DEV") {
    ABI = TestBurnRedeemContractInfo.ABI;
    BaseContractAddress = TestBaseContractInfo.ContractAddress;
    startId = TestBaseContractInfo.ClaimTokenCount + 1;
  } else {
    ABI = BurnRedeemContractInfo.ABI;
    BaseContractAddress = BaseContractInfo.ContractAddress;
    startId = BaseContractInfo.ClaimTokenCount + 1;
  }
  const RPC = process.env.REACT_APP_RPC;
  const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
  const resultData = JSON.parse(JSON.stringify(rawData));

  for (let chapter = 0; chapter < 5; chapter++) {
    for (let stage = 0; stage < rawData[chapter].length; stage++) {
      const contract = new web3.eth.Contract(
        ABI,
        rawData[chapter][stage].publicData.extensionAddress
      );
      let result = await contract.methods
        .getBurnRedeem(BaseContractAddress, rawData[chapter][stage].id)
        .call();
      console.log(
        "asyncGetBurnSettingData : " +
          Number(chapter + 1) +
          "-" +
          Number(stage + 1),
        Number(result.redeemedCount)
      );
      let finishId = startId + Number(result.redeemedCount) - 1;
      resultData[chapter][stage].redeemedCount = Number(result.redeemedCount);
      if (Number(result.redeemedCount) === 0) {
        resultData[chapter][stage].startId = 0;
        resultData[chapter][stage].finishId = 0;
      } else {
        resultData[chapter][stage].startId = startId;
        resultData[chapter][stage].finishId = finishId;
      }
      startId = finishId + 1;
    }
  }
  return resultData;
}

export {
  asyncPostSettings,
  asyncPostBurnSettings,
  asyncGetBurnSettingData,
  asyncPostOpenBonus,
  asyncPostChangeMain,
  asyncGetIsBonusOpen,
  asyncGetIsBonusMain,
};
export const serverActions = serverSlice.actions;
export default serverSlice.reducer;
