import styled from "styled-components";
import { Colors } from "../styles/Themes";

const IconEl = styled.svg`
  fill: ${(props) => (props.highlight ? Colors.highlight : Colors.wt)};
`;

function HomeIcon(props) {
  return (
    <IconEl
      width="20"
      height="18"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      highlight={props.highlight}
    >
      <path d="M10 3.19L15 7.69V15.5H13V9.5H7V15.5H5V7.69L10 3.19ZM10 0.5L0 9.5H3V17.5H9V11.5H11V17.5H17V9.5H20L10 0.5Z" />
    </IconEl>
  );
}

function AboutIcon(props) {
  return (
    <IconEl
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      highlight={props.highlight}
    >
      <path
        d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      />
    </IconEl>
  );
}

export { HomeIcon, AboutIcon };
