import styled from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetBurnSettingData,
  asyncGetIsBonusOpen,
} from "../store/serverSlice";
import MainBurnGrid from "../components/MainBurnGrid";
import { Sizes } from "../styles/Themes";
import { useWeb3React } from "@web3-react/core";

const AdminEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  font-size: 15px;
  font-family: NanumSquare;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 16px);
`;

const Title = styled.span`
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
`;

const RefreshButton = styled.button`
  width: 130px;
  font-size: 20px;
`;

function MainPage() {
  const settingData = useSelector((state) => state.server.settingData);

  const dispatch = useDispatch();
  const [isBonusOpen, setIsBonusOpen] = useState(false);

  const { account } = useWeb3React();

  const serverBonusOpenData = useSelector((state) => state.server.isBonusOpen);

  useEffect(() => {
    dispatch(asyncGetIsBonusOpen());
    dispatch(asyncGetBurnSettingData());
  }, []);

  useEffect(() => {
    setIsBonusOpen(serverBonusOpenData);
  }, [serverBonusOpenData]);

  function refreshHandler() {
    dispatch(asyncGetIsBonusOpen());
    dispatch(asyncGetBurnSettingData());
  }

  return (
    <AdminEl>
      {account !== undefined && settingData !== undefined &&
        (account === process.env.REACT_APP_OWNER ||
          account === process.env.REACT_APP_DEVELOPER) && (
          <>
            <Title>Setting Data</Title>
            <RefreshButton onClick={refreshHandler}>Refresh</RefreshButton>
            {settingData.length !== 0 ? (
              <MainBurnGrid data={settingData} isBonusOpen={isBonusOpen} />
            ) : (
              <div></div>
            )}
          </>
        )}
    </AdminEl>
  );
}

export default MainPage;
