import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logMsg : ""
};

const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    pushLogMsg(state,action){
        const date = new Date();
      state.logMsg = (date.toISOString() +" | "+ action.payload + "\n") + state.logMsg;
    },
  },
});

export const componentActions = componentSlice.actions;
export default componentSlice.reducer;