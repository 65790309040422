import { useEffect } from "react";
import styled from "styled-components";
import MainDataGrid from "./MainDataGrid";

const BurnGreedEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 20px;
`;

function MainBurnGrid({ data, isBonusOpen }) {
  useEffect(() => {
    console.log("BurnGrid", data);
  }, []);
  return (
    <>
      <BurnGreedEl>
        <Title>Chapter 1</Title>
        <MainDataGrid data={data[0]} />
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 2</Title>
        <MainDataGrid data={data[1]} />
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 3</Title>
        <MainDataGrid data={data[2]} />
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 4</Title>
        <MainDataGrid data={data[3]} />
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Burn Them All</Title>
        <MainDataGrid data={data[5]} />
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Bonus Chapter ({isBonusOpen ? "OPEN" : "CLOSED"})</Title>
        <MainDataGrid data={data[4]} />
      </BurnGreedEl>
    </>
  );
}
export default MainBurnGrid;
