import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetBurnData } from "../store/manifoldBurnSlice";
import {
  asyncPostBurnSettings,
  asyncPostOpenBonus,
  asyncGetIsBonusOpen,
  asyncGetIsBonusMain,
  asyncPostChangeMain,
} from "../store/serverSlice";
import BurnGrid from "../components/BurnGrid";
import { componentActions } from "../store/componentSlice";
import { Sizes } from "../styles/Themes";

const AdminEl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  font-size: 15px;
  font-family: NanumSquare;
  min-height: calc(100vh - ${Sizes.HeaderHeight} - ${Sizes.FooterHeight});
  padding-top: calc(${Sizes.HeaderHeight} + 16px);
`;

const LoadDataButton = styled.button`
  width: 450px;
  font-size: 25px;
`;

const SaveButton = styled.button`
  width: 250px;
  font-size: 25px;
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
`;

const LogMsg = styled.p`
  width: 100%;
  height: 100px;
  border: 1px solid gray;
  max-height: 100px;
  overflow-y: scroll;
`;

const ErrorMsg = styled.span`
  background: gray;
  width: 100%;
  font-size: 20px;
  color: white;
  padding: 10px;
  box-sizing: border-box;
`;

const ToggleEl = styled.div`
  width: 50px;
  height: 25px;
  border-radius: 20%;
  border: 1px solid gray;
  display: flex;
  justify-content: ${(props) =>
    props.$isBonusOpen ? "flex-end" : "flex-start"};
  cursor: pointer;
`;

const ToggleButton = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 70%;
  background-color: ${(props) => (props.$isBonusOpen ? "blue" : "gray")};
`;

function MainPage() {
  const { account } = useWeb3React();
  const isBurnLoading = useSelector((state) => state.manifoldBurn.isLoading);
  const saveStatus = useSelector((state) => state.server.status);
  const burnRawData = useSelector((state) => state.manifoldBurn.rawData);
  const [logText, setLogText] = useState("");

  const dispatch = useDispatch();
  const [isBonusOpen, setIsBonusOpen] = useState(false);
  const [isBonusMain, setIsBonusMain] = useState(false);

  const logMsg = useSelector((state) => state.component.logMsg);
  const serverBonusOpenData = useSelector((state) => state.server.isBonusOpen);
  const serverIsBonusOpenData = useSelector((state) => state.server.isBonusMain);

  useEffect(() => {
    dispatch(asyncGetIsBonusOpen());
    dispatch(asyncGetIsBonusMain());

    console.log = function (message) {
      if (typeof message == "object") {
        setLogText(
          logText +
            (JSON && JSON.stringify ? JSON.stringify(message) : message) +
            "\n"
        );
      } else {
        dispatch(componentActions.pushLogMsg(message));
      }
    };
  }, []);

  useEffect(() => {
    setIsBonusOpen(serverBonusOpenData);
    setIsBonusMain(serverIsBonusOpenData);
  }, [serverBonusOpenData,serverIsBonusOpenData]);

  function loadDataHandler() {
    dispatch(asyncGetBurnData({ account, isTest: false }));
  }

  function testHandler() {
    dispatch(asyncGetBurnData({ account, isTest: true }));
  }

  function saveDataHandler() {
    dispatch(asyncPostBurnSettings());
  }

  function bonusOpenHandler() {
    dispatch(asyncPostOpenBonus({ isBonusOpen: !isBonusOpen }));
    setIsBonusOpen(!isBonusOpen);
  }

  function mainChangeHandler() {
    dispatch(asyncPostChangeMain({ isBonusMain: !isBonusMain }));
    setIsBonusMain(!isBonusMain);
  }

  async function copyContent() {
    try {
      await navigator.clipboard.writeText(logMsg);
      console.log("Logs copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: " + err);
    }
  }

  return (
    <AdminEl>
      {account !== undefined &&
        (account === process.env.REACT_APP_OWNER ||
          account === process.env.REACT_APP_DEVELOPER) && (
          <>
            <span>WALLET ADDRESS : {account}</span>
            {account === process.env.REACT_APP_DEVELOPER && (
              <LoadDataButton onClick={testHandler}>Test</LoadDataButton>
            )}
            <LoadDataButton onClick={loadDataHandler}>
              1. Load Data(Manifold) {isBurnLoading ? "(Loading)" : ""}
            </LoadDataButton>

            <LogMsg>
              {logMsg.split("\n").map((txt, index) => (
                <span key={index}>
                  {txt}
                  <br />
                </span>
              ))}
            </LogMsg>
            <SaveButton onClick={copyContent}>Copy log</SaveButton>

            {burnRawData.length !== 0 ? (
              <BurnGrid data={burnRawData.data} isBonusOpen={isBonusOpen} />
            ) : (
              <div></div>
            )}

            <ErrorMsg>{burnRawData.errorMsg}</ErrorMsg>
            <SaveButton onClick={saveDataHandler}>2. Save</SaveButton>
            <span>Save status : {saveStatus}</span>
            <Line />
            <span>Bonus chapter menu control</span>
            <ToggleEl $isBonusOpen={isBonusOpen} onClick={bonusOpenHandler}>
              <ToggleButton $isBonusOpen={isBonusOpen}></ToggleButton>
            </ToggleEl>
            <span>Set Bonus chapter main(On일 경우 보너스챕터가 먼저 노출됩니다.)</span>
            <ToggleEl $isBonusOpen={isBonusMain} onClick={mainChangeHandler}>
              <ToggleButton $isBonusOpen={isBonusMain}></ToggleButton>
            </ToggleEl>
          </>
        )}
    </AdminEl>
  );
}

export default MainPage;
