import { Link as ReactRouterDomLink } from "react-router-dom";
import styled from "styled-components";
import { Colors, ScreenSizes, Sizes } from "../../styles/Themes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectWalletButton from "../buttons/ConnectWalletButton";
import MobileMenu from "./MobileMenu";
import { componentActions } from "../../store/componentSlice";
import { useWeb3React } from "@web3-react/core";

const Link = ({ children, isActive, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const HeaderEl = styled.header`
  display: flex;
  position: fixed;
  top: 0px;
  width: 100%;
  height: ${Sizes.HeaderHeight};
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.gr02};
  box-sizing: border-box;
  z-index: 500;
  padding: 0px 32px 0px 32px;
  filter: ${(props) => (props.blur ? "blur(2px);" : "none;")};
  @media (max-width: ${ScreenSizes.mobile}) {
    height: ${Sizes.MobileHeaderHeight};
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 0px;
    padding-bottom: ${(props) => (props.$bottomLine ? "0px" : "0.8px")};
    border-bottom: ${(props) => (props.$bottomLine ? "1px solid #F2F2F2" : "")};
  }
`;

const HeaderLeftEl = styled.div`
  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 0px 0px 0px 8px;
    display: flex;
    align-items: center;
  }
`;

const HeaderLogoBox = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  gap:4px;
  text-decoration: none;
`;

const HeaderAdminText = styled.span`
  color:${Colors.highlight};
  font-family:RovertSans;
  font-size:21px;
  font-weight:600;
`;

const HeaderLogoImg = styled.img`
  width: auto;
  height: 16px;
  @media (max-width: ${ScreenSizes.mobile}) {
    width: auto;
    height: 12px;
  }
`;

const HeaderRightEl = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: none;
  }
`;

const HeaderMenuItem = styled(Link)`
  width: 168px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.gr07};
  font-family: NanumSquare;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    color: ${Colors.highlight};
    border-bottom: 1px solid ${Colors.highlight};
  }
`;

const HeaderMobileRightEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    align-items: center;
  }
`;

const MobileMenuButtonImg = styled.img``;

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isBlur = useSelector((state) => state.component.isBlur);
  const isBonusOpen = useSelector((state) => state.server.isBonusOpen);
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  function mobileMenuHandler() {
    dispatch(componentActions.setMobileMenuOpen());
    dispatch(componentActions.setIsBlur(!isMobileMenuOpen));
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }
  return (
    <>
      <HeaderEl $bottomLine={isMobileMenuOpen} blur={isBlur}>
        <HeaderLeftEl>
          <HeaderLogoBox to={"/"}>
            <HeaderLogoImg src={"/images/header_logo.svg"} alt="logo image" />
            <HeaderAdminText>ADMIN</HeaderAdminText>
          </HeaderLogoBox>
        </HeaderLeftEl>
        <HeaderRightEl>
        <HeaderMenuItem to={"/"}>Home</HeaderMenuItem>
          <HeaderMenuItem to={"/setting"}>Setting</HeaderMenuItem>
          <ConnectWalletButton />
        </HeaderRightEl>
        <HeaderMobileRightEl onClick={mobileMenuHandler}>
          <MobileMenuButtonImg src={"/icons/menu_24px.svg"} />
        </HeaderMobileRightEl>
      </HeaderEl>
      {isMobileMenuOpen && <MobileMenu mobileMenuHandler={mobileMenuHandler} account={account} isBonusOpen={isBonusOpen}/>}
    </>
  );
}

export default Header;
