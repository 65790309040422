import { configureStore } from "@reduxjs/toolkit";

import manifoldClaimSlice from "./manifoldClaimSlice";
import manifoldBurnSlice from "./manifoldBurnSlice";
import serverSlice from './serverSlice';
import componentSlice from './componentSlice';

const store = configureStore({
  reducer: {
    manifoldClaim: manifoldClaimSlice,
    manifoldBurn: manifoldBurnSlice,
    server: serverSlice,
    component: componentSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
