import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import Web3 from "web3";
import baseContract from "../contract/ERC721Creator.json";

const RPC = process.env.REACT_APP_RPC;
const web3 = new Web3(new Web3.providers.HttpProvider(RPC));

const initialState = {
  isLoading: false,
  rawData: [],
};

const manifoldBurnSlice = createSlice({
  name: "manifoldBurnSlice",
  initialState,
  reducers: {
    selectData(state, action) {
      state.selectedData = current(state.rawData[action.payload]);
      console.log(state.selectedData);
      state.isSelected = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetBurnData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncGetBurnData.fulfilled, (state, action) => {
        state.rawData = action.payload;
        state.isLoading = false;
      })
      .addCase(asyncGetBurnData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

const asyncGetBurnData = createAsyncThunk(
  "manifoldClaimSlice/asyncGetBurnData",
  async (params, { rejectWithValue }) => {
    try {
      const { account, isTest } = params;
      let API_URL;
      if(isTest){
        API_URL = "http://localhost:5000"
      }else{
        API_URL = process.env.REACT_APP_MANIFOLD_API_URL;
      }
      const res = await axios.get(
        API_URL +
          "/public/instance/all?appId=2534903479&address=" +
          "0x4aDf0dbc8bB29A61BE114C22879202A76790C9d9"
      );
      const filteredData = filterData(res.data);
      sortData(filteredData);
      let resultData = {};
      const errorMsg = checkError(filteredData);
      resultData.errorMsg = errorMsg;
      resultData.data = await setTokenURIs(filteredData);
      console.log("---FINISH LOAD DATA---");
      return resultData;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

function filterData(rawData) {
  const resultData = [];
  const resultDataCh1 = [];
  const resultDataCh2 = [];
  const resultDataCh3 = [];
  const resultDataCh4 = [];
  const resultDataCh5 = [];
  const resultDataCh6 = []; //for BTA
  for (let i = 0; i < rawData.length; i++) {
    if (rawData[i].slug !== null) {
      console.log("filterData slug : " + rawData[i].slug);
      rawData[i].chapter = Number(rawData[i].slug.split("-")[0]);
      rawData[i].stage = Number(rawData[i].slug.split("-")[1]);
      rawData[i].publicData.textStartDate = convertDate(
        rawData[i].publicData.startDate
      );
      rawData[i].publicData.textEndDate = convertDate(
        rawData[i].publicData.endDate
      );
      switch (rawData[i].chapter) {
        case 1:
          resultDataCh1.push(rawData[i]);
          break;
        case 2:
          resultDataCh2.push(rawData[i]);
          break;
        case 3:
          resultDataCh3.push(rawData[i]);
          break;
        case 4:
          resultDataCh4.push(rawData[i]);
          break;
        case 5:
          resultDataCh5.push(rawData[i]);
          break;
        case 6:
          resultDataCh6.push(rawData[i]);
          break;
        default:
          break;
      }
    }
  }
  resultData.push(resultDataCh1);
  resultData.push(resultDataCh2);
  resultData.push(resultDataCh3);
  resultData.push(resultDataCh4);
  resultData.push(resultDataCh5);
  resultData.push(resultDataCh6);
  console.log("resultData", resultData);
  return resultData;
}

function sortData(rawData) {
  for (let i = 0; i < 6; i++) {
    rawData[i].sort((a, b) => {
      if (a.chapter === b.chapter) {
        return a.stage - b.stage;
      } else {
        return a.chapter - b.chapter;
      }
    });
  }
}

function checkError(rawData) {
  let errorMsg = "";
  for (let i = 0; i < 6; i++) {
    let endCount = 0;
    for (let j = 0; j < rawData[i].length; j++) {
      //E001 : more than 2 end stage
      if (rawData[i][j].stage === "e") {
        endCount++;
        if (endCount > 1)
          errorMsg +=
            "E001:" + rawData[i][j].chapter + "_" + rawData[i][j].stage + "/";
      }

      //E002 : there is no start date
      if (rawData[i][j].publicData.startDate === 0)
        errorMsg +=
          "E002:" + rawData[i][j].chapter + "_" + rawData[i][j].stage + "/";

      //E003 : there are same stage number in chapter
      if (j > 0) {
        if (rawData[i][j - 1].stage === rawData[i][j].stage)
          errorMsg +=
            "E003:" + rawData[i][j].chapter + "_" + rawData[i][j].stage + "/";
      }

      //E004 : the end date of the precedign stage is 0, but the start stage is set
      if (j > 0) {
        if (rawData[i][j - 1].publicData.endDate === 0)
          errorMsg +=
            "E004:" + rawData[i][j].chapter + "_" + rawData[i][j].stage + "/";
      }

      //E005 : the start date of this stage is faster than the end date of the precedign stage
      if (j > 0) {
        if (
          rawData[i][j - 1].publicData.endDate >
          rawData[i][j].publicData.startDate
        )
          errorMsg +=
            "E005:" + rawData[i][j].chapter + "_" + rawData[i][j].stage + "/";
      }
    }
  }
  return errorMsg;
}

function convertDate(timestamp) {
  if (timestamp === 0) {
    return "-";
  }
  const targetDate = new Date(timestamp * 1000);
  const year = targetDate.getFullYear();
  const month =
    targetDate.getMonth() + 1 < 10
      ? "0" + (targetDate.getMonth() + 1)
      : targetDate.getMonth() + 1;
  const date =
    targetDate.getDate() < 10
      ? "0" + targetDate.getDate()
      : targetDate.getDate();
  const hour =
    targetDate.getHours() < 10
      ? "0" + targetDate.getHours()
      : targetDate.getHours();
  const minute =
    targetDate.getMinutes() < 10
      ? "0" + targetDate.getMinutes()
      : targetDate.getMinutes();
  return year + "-" + month + "-" + date + " " + hour + ":" + minute;
}

async function setTokenURIs(rawData) {
  let data = JSON.parse(JSON.stringify(rawData));
  for (let chapter = 0; chapter < 6; chapter++) {
    for (let stage = 0; stage < data[chapter].length; stage++) {
      let burnSet = data[chapter][stage].publicData.burnSet;
      for (let i = 0; i < burnSet.length; i++) {
        const contractAddress = burnSet[i].items[0].contractAddress;
        console.log(Number(chapter + 1) + "/" + Number(stage+1) + "-" + i + "setTokenURIs - contractAddress : " + contractAddress);
        const contract = new web3.eth.Contract(
          baseContract.abi,
          contractAddress
        );

        const minTokenId = burnSet[i].items[0].minTokenId;
        const maxTokenId = burnSet[i].items[0].maxTokenId;
        for (let id = maxTokenId; id >= minTokenId; id--) {
          console.log("setTokenURIs - tokenId : " + id);
          try {
            const tokenURI = await contract.methods.tokenURI(id).call();
            const jsonURI = await axios.get(process.env.REACT_APP_ARWEAVE_URL + tokenURI.split("/").pop());
            burnSet[i].items[0].uri = jsonURI.data;
            console.log("[Success]tokenURI : " + JSON.stringify(tokenURI));
            break;
          } catch (e) {
            console.log(
              "[Error]setTokenURIs : Chapter" +
                Number(chapter + 1) +
                " / Stage" +
                Number(stage + 1)
                +
                " / id : "
                +
                id
            );
            console.log("[Error]setTokenURIs : " + e);
          }
        }
      }
    }
  }
  return data;
}

export { asyncGetBurnData };
export const manifoldBurnActions = manifoldBurnSlice.actions;
export default manifoldBurnSlice.reducer;
