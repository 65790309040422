import { useEffect } from 'react';
import styled from "styled-components";
import DataGrid from './DataGrid';

const BurnGreedEl = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 20px;
`;



function BurnGrid({ data, isBonusOpen }) {
  useEffect(()=>{
console.log("BurnGrid",data);
  },[])
  return (
    <>
      <BurnGreedEl>
        <Title>Chapter 1</Title>
        <DataGrid data={data[0]}/>
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 2</Title>
        <DataGrid data={data[1]}/>
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 3</Title>
        <DataGrid data={data[2]}/>
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Chapter 4</Title>
        <DataGrid data={data[3]}/>
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Burn Them All</Title>
        <DataGrid data={data[5]}/>
      </BurnGreedEl>
      <BurnGreedEl>
        <Title>Bonus Chapter ({isBonusOpen ? "OPEN" : "CLOSED"})</Title>
        <DataGrid data={data[4]}/>
      </BurnGreedEl>
    </>
  );
}
export default BurnGrid;
