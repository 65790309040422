import styled from "styled-components";

const DataGridEl = styled.table``;

const GridHeader = styled.th`
  border: 1px solid black;
`;

const GridTr = styled.tr``;
const GridTd = styled.td`
  border: 1px solid black;
  text-align: center;
`;

function DataGrid({ data }) {
  function getState(startDate, endDate) {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (startDate === 0) return "error";
    if (start <= now && endDate === 0) return "now";
    if (start <= now && now <= end) return "now";
    if (end < now && endDate !== 0) return "finish";
    if (now < start) return "waiting";
  }
  return (
    <>
    {data !== undefined &&
      <DataGridEl>
        <thead>
          <tr>
            <GridHeader>Stage</GridHeader>
            <GridHeader>Name</GridHeader>
            <GridHeader>Start Date</GridHeader>
            <GridHeader>End Date</GridHeader>
            <GridHeader>Burn Set</GridHeader>
            <GridHeader>Redeem</GridHeader>
            <GridHeader>State</GridHeader>
          </tr>
        </thead>
        <tbody>
          {data.map(
            (row, index) =>
              row.slug !== null && (
                <GridTr key={index}>
                  <GridTd>{row.stage}</GridTd>
                  <GridTd>{row.publicData.name}</GridTd>
                  <GridTd>{row.publicData.textStartDate}</GridTd>
                  <GridTd>{row.publicData.textEndDate}</GridTd>
                  <GridTd>{row.publicData.burnSet.length}</GridTd>
                  <GridTd>Show</GridTd>
                  <GridTd>
                    {getState(row.publicData.startDate, row.publicData.endDate)}
                  </GridTd>
                </GridTr>
              )
          )}
        </tbody>
      </DataGridEl>}
    </>
  );
}
export default DataGrid;
