import { Link as ReactRouterDomLink, useLocation } from "react-router-dom";
import { Colors, ScreenSizes } from "../../styles/Themes";
import styled from "styled-components";
import { HomeIcon, AboutIcon } from "../../styles/Icons";
import ConnectWalletButton from "../buttons/ConnectWalletButton";

const Link = ({ children, isActive, ...props }) => {
  return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const MobileMenuBackGroundEl = styled.div`
  display: none;
  @media (max-width: ${ScreenSizes.mobile}) {
    display: inline-block;
    position: fixed;
    top: 39px;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const MobileMenuEl = styled.aside`
  @media (max-width: ${ScreenSizes.mobile}) {
    position: fixed;
    top: 39px;
    background-color: #101010;
    width: 240px;
    height: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const MobileMenuListEl = styled.ul`
  @media (max-width: ${ScreenSizes.mobile}) {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
`;

const MobileMenuListItem = styled.li``;

const ItemLinkBox = styled(Link)`
  @media (max-width: ${ScreenSizes.mobile}) {
    display: flex;
    height: 56px;
    width: 100%;
    color: ${(props) => (props.selected ? Colors.highlight : Colors.wt)};
    list-style: none;
    border-bottom: 1px solid #525252;
    align-items: center;
    padding-left: 16px;
    gap: 12px;
    font-family: NanumSquare;
    font-weight: 400;
    box-sizing: border-box;
    text-decoration: none;
  }
`;

function MobileMenu(prams) {
  const { mobileMenuHandler, account, isBonusOpen } = prams;
  const urlLocation = useLocation().pathname;
  return (
    <MobileMenuBackGroundEl onClick={mobileMenuHandler}>
      <MobileMenuEl onClick={(event) => event.stopPropagation()}>
        <MobileMenuListEl>
          <MobileMenuListItem>
            <ItemLinkBox
              to={"/"}
              selected={"/" === urlLocation}
              onClick={mobileMenuHandler}
            >
              <HomeIcon highlight={"/" === urlLocation} />
              Home
            </ItemLinkBox>
          </MobileMenuListItem>

          <MobileMenuListItem>
            <ItemLinkBox
              to={"/setting"}
              selected={"/setting" === urlLocation}
              onClick={mobileMenuHandler}
            >
              <AboutIcon highlight={"/setting" === urlLocation} />
              Setting
            </ItemLinkBox>
          </MobileMenuListItem>
        </MobileMenuListEl>
        <ConnectWalletButton />
      </MobileMenuEl>
    </MobileMenuBackGroundEl>
  );
}
export default MobileMenu;
