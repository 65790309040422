import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import Web3 from "web3";
import CliamContractInfo from "../contract/ERC721LazyPayableClaim.json";

const RPC = "https://mainnet.infura.io/v3/88d99369853c441d984ed94800cd761a";

const web3 = new Web3(new Web3.providers.HttpProvider(RPC));

const ClaimContractAddress = "0x1EB73FEE2090fB1C20105d5Ba887e3c3bA14a17E";

var claimContract = new web3.eth.Contract(
  CliamContractInfo.abi,
  ClaimContractAddress
);

const initialState = {
  isLoading: false,
  rawData: [],
  selectedData: [],
  isSelected: false,
};

const manifoldClaimSlice = createSlice({
  name: "manifoldClaimSlice",
  initialState,
  reducers: {
    selectData(state,action){
      state.selectedData = current(state.rawData[action.payload]);
      console.log(state.selectedData);
      state.isSelected = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetClaimData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncGetClaimData.fulfilled, (state, action) => {
        state.rawData = action.payload;
        state.isLoading = false;
      });
  },
});

const asyncGetClaimData = createAsyncThunk(
  "manifoldClaimSlice/asyncGetClaimData",
  async (account) => {
    let res = await axios.get(
      "https://apps.api.manifoldxyz.dev/public/instance/all?appId=" +
        "2537426615" +
        "&address=" +
        account
    );
    // console.log("res.data",res.data);
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].slug !== null && res.data[i].publicData.extensionAddress === ClaimContractAddress.toLowerCase()) {
        // console.log("creatorContractAddress",res.data[i].publicData.creatorContractAddress);
        // console.log("claimIndex",res.data[i].publicData.claimIndex);
        let claim = await claimContract.methods.getClaim(
          res.data[i].publicData.creatorContractAddress,
          res.data[i].publicData.claimIndex
        ).call();
        res.data[i].publicData.startDate = claim.startDate.toString();
        res.data[i].publicData.endDate = claim.endDate.toString();
      }
    }
    return res.data;
  }
);

export { asyncGetClaimData };
export const manifoldClaimActions = manifoldClaimSlice.actions;
export default manifoldClaimSlice.reducer;
