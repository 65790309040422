import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootPage from "./pages/RootPage";
import MainPage from "./pages/MainPage";
import SettingPage from "./pages/SettingPage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncGetIsBonusOpen } from "./store/serverSlice";
import { componentActions } from "./store/componentSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetIsBonusOpen());

    console.log = function (message) {
        dispatch(componentActions.pushLogMsg(message));
    };
  }, []);

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/",
          element: <RootPage />,
          children: [
            {
              path: "/",
              element: <MainPage />,
            },
            {
              path: "/setting",
              element: <SettingPage />,
            },
            {
              path: "/*",
              element: <MainPage />,
            },
          ],
        },
      ])}
    ></RouterProvider>
  );
}

export default App;
